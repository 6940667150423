








import { Component, Prop, Vue } from 'vue-property-decorator';
import { ServerForm } from '@/components/form/serverForm';

@Component
export default class FCsrfTokenField extends Vue {
    @Prop({ type: Object, required: true })
    form!: ServerForm

}
