




type InnerFormErrors<T> = Record<string|number, T>
export type FormErrorsInterface = InnerFormErrors<string | InnerFormErrors<any>>

import { Component, Prop, Vue } from 'vue-property-decorator';


@Component
export default class FormErrorList extends Vue {
    @Prop({ required: false, default: {} })
    serverErrors!: FormErrorsInterface;

    get flatErrorsTemplate(): string {
        return this.generateErrorsTemplate(this.serverErrors);
    }

    isNumericalString(value: any): boolean {
        return typeof value === 'string' && !isNaN(Number(value));
    }

    generateErrorsTemplate(errors: FormErrorsInterface): string {
        let template = '';

        for (const key in errors) {
            const value = errors[key];

            let hasLi = false;

            if (!this.isNumericalString(key)) {
                hasLi = true;
                template += `<li class="text-danger">`
                template += `<b>${this.$t(key)}</b>:`;
            }

            if (typeof value === 'object' || Array.isArray(value)) {
                if (hasLi) {
                    template += `<ul style="padding-left: 1rem;">`;
                }
                template += this.generateErrorsTemplate(value);

                if (hasLi) {
                    template += `</ul>`;
                }
            } else {
                hasLi = true;
                template += `<li class="text-danger">`
                template += `${this.$t(value as string)}`;
            }

            if (hasLi) {
                template += '</li>';
            }
        }

        return template;
    }
}
