var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.form.initialized
    ? _c(
        "div",
        [
          _vm.renderToken
            ? _c("f-csrf-token-field", {
                attrs: {
                  form: _vm.form.form
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.form.children, function(child) {
            return [
              child.children && child.childType === "collection"
                ? _c(
                    "fieldset",
                    {
                      key: "collection-" + child.name
                    },
                    [
                      _vm._l(child.children, function(subChild) {
                        return _vm._l(subChild.children, function(field) {
                          return _c("f-server-form-child", {
                            key: _vm.uniqueId(field.name),
                            attrs: {
                              child: field
                            },
                            on: {
                              input: function($event) {
                                return _vm.form.onValueChange(subChild, $event)
                              }
                            },
                            model: {
                              value:
                                _vm.form.values[child.name][subChild.name][
                                  field.name
                                ],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.values[child.name][subChild.name],
                                  field.name,
                                  $$v
                                )
                              },
                              expression:
                                "form.values[child.name][subChild.name][field.name]"
                            }
                          })
                        })
                      })
                    ],
                    2
                  )
                : [
                    _c("f-server-form-child", {
                      key: child.name,
                      attrs: {
                        child: child
                      },
                      on: {
                        input: function($event) {
                          return _vm.form.onValueChange(child, $event)
                        }
                      },
                      model: {
                        value: _vm.form.values[child.name],
                        callback: function($$v) {
                          _vm.$set(_vm.form.values, child.name, $$v)
                        },
                        expression: "form.values[child.name]"
                      }
                    })
                  ]
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }