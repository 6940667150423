export default class UrlUtil {
    static createAbsoluteUrlObject(url: string) {
        if (!url.includes('http')) {
            const separator = url.startsWith('/') ? '' : '/';

            url = window.location.origin + separator + url;
        }

        return new URL(url);
    }
}
