var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "section",
    [
      !_vm.hideLoader && _vm.loading && _vm.initialized
        ? _c("f-loader", {
            staticClass: "w-100 h-100 position-absolute",
            attrs: {
              center: ""
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          class: {
            loading: _vm.loading && _vm.initialized
          },
          attrs: {
            id: _vm.idString,
            method: "post",
            name: _vm.form ? _vm.form.name : null
          },
          on: {
            submit: _vm.handleSubmit
          }
        },
        [
          [
            !_vm.hideErrors &&
            !_vm.errorsRenderedExternally &&
            Object.keys(_vm.serverErrors).length > 0
              ? _c("form-errors", {
                  attrs: {
                    "server-errors": _vm.serverErrors
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "contentWrapper",
                staticClass: "f-server-form-content"
              },
              [
                _vm._t(
                  "default",
                  [
                    _c(
                      "f-server-form-loader",
                      {
                        attrs: {
                          loading: _vm.contentIsLoading,
                          "hide-loader": _vm.hideLoader,
                          "disable-placeholders": _vm.disablePlaceholders,
                          "form-cache": _vm.cache,
                          "child-placeholder-map": _vm.formContext.getChildPlaceholderMap()
                        }
                      },
                      [
                        _c("f-server-form-widgets", {
                          attrs: {
                            form: _vm.self,
                            "render-token": _vm.nativeSubmit
                          }
                        })
                      ],
                      1
                    )
                  ],
                  {
                    children: _vm.children,
                    values: _vm.values,
                    additionalData: _vm.form.additionalData,
                    valueObjects: _vm.valueObjects,
                    childChoices: _vm.childChoices,
                    errorState: _vm.errorState,
                    form: _vm.self,
                    loading: _vm.contentIsLoading
                  }
                )
              ],
              2
            )
          ],
          _vm._v(" "),
          _vm.withSaveButton
            ? _c("f-save-button", {
                attrs: {
                  form: _vm.self
                }
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }