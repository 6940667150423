





































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { TranslateMixin } from '@/util/translate';
import FServerFormChild from '@/components/form/f-server-form-child.vue';

import FServerForm from '@/components/form/f-server-form.vue';
import FCsrfTokenField from '@/components/form/f-csrf-token-field.vue';
import { uniqueId } from 'lodash';

@Component({
    components: {
        FCsrfTokenField,
        FServerFormChild
    },
    methods: {
        uniqueId,
    }
})
export default class FServerFormWidgets extends Mixins(TranslateMixin) {
    @Prop({ required: true })
    form!: FServerForm

    @Prop({ type: Boolean, default: false })
    renderToken!: boolean;
}
