










import { Component, Prop, Vue } from 'vue-property-decorator';
import FormErrorList, { FormErrorsInterface } from '@/components/form-error-list.vue';

@Component({
    components: { FormErrorList }
})
export default class FormErrors extends Vue {
    @Prop({ required: false, default: function () { return {}; } })
    serverErrors!: FormErrorsInterface;

    @Prop()
    bind!: () => FormErrorsInterface;

    get errors(): FormErrorsInterface {
        if (this.bind) {
            return this.bind();
        }

        return this.serverErrors;
    }
}
