var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("ul", {
    staticStyle: {
      "padding-left": "1.3rem"
    },
    domProps: {
      innerHTML: _vm._s(_vm.flatErrorsTemplate)
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }