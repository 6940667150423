var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: Object.keys(_vm.errors).length > 0,
          expression: "Object.keys(errors).length > 0"
        }
      ]
    },
    [
      _c(
        "p",
        {
          staticClass: "text-danger text-bold",
          staticStyle: {
            "margin-bottom": "0.5rem"
          }
        },
        [
          _c("span", {
            staticClass: "fa fa-fw fa-exclamation-triangle"
          }),
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("common.following_errors_occurred")) +
              "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c("form-error-list", {
        attrs: {
          "server-errors": _vm.errors
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }