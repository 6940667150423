import { FormChildType, ServerFormChildren } from '@/components/form/serverForm';

interface FServerFormContentCacheData {
    // Ex: 800px
    contentHeight: string
    fields: FormChildType[]
}

class ServerFormContentCache {
    private cache: FServerFormContentCacheData = Object.freeze({
        contentHeight: '50vh',
        fields: [],
    });

    constructor(private id: string) {
    }

    private get cacheKey(): string {
        return `@FServerForm-${this.id}`;
    }

    loadCache(): void {
        const data = localStorage.getItem(this.cacheKey) as string|undefined;

        if (!data) {
            return;
        }

        let jsonData;

        try {
            jsonData = JSON.parse(data);
        } catch (e) {
            console.error('An error occurred while loading the form cache.');
            localStorage.removeItem(this.cacheKey);
            return;
        }

        this.cache = Object.assign({}, this.cache, jsonData);
    }

    getData(): FServerFormContentCacheData {
        return this.cache;
    }

    persistCache(children: ServerFormChildren, contentWrapper: HTMLDivElement) {
        const rect = contentWrapper.getBoundingClientRect();
        const loadingIconHeight = 16;

        this.cache = {
            contentHeight: rect.height + loadingIconHeight + 'px',
            fields: Object.values(children).map((child) => child.childType),
        };

        this.save(this.cache);
    }

    private save(options: FServerFormContentCacheData) {
        localStorage.setItem(this.cacheKey, JSON.stringify(options));
    }
}

export { FServerFormContentCacheData };
export default ServerFormContentCache;
