var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("f-loader", {
    attrs: {
      loading: !_vm.hideLoader && _vm.loading,
      center: !_vm.placeholdersEnabled,
      "min-height": _vm.cacheData.contentHeight
    },
    scopedSlots: _vm._u(
      [
        _vm.placeholdersEnabled
          ? {
              key: "loader",
              fn: function() {
                return _vm._l(_vm.cacheData.fields, function(field, key) {
                  return _c("f-server-form-child-placeholders", {
                    key: "server-form-loader-" + field + "-" + key,
                    attrs: {
                      type: field,
                      "child-placeholder-map": _vm.childPlaceholderMap,
                      "disable-padding-top": key === 0
                    }
                  })
                })
              },
              proxy: true
            }
          : null,
        {
          key: "default",
          fn: function() {
            return [_vm._t("default")]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }