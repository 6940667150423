var render = function() {
  var _vm$form$children$_to, _vm$form$children, _vm$form$children$_to2

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("input", {
    attrs: {
      type: "hidden",
      name: _vm.form.name + "[_token]"
    },
    domProps: {
      value:
        (_vm$form$children$_to =
          (_vm$form$children = _vm.form.children) === null ||
          _vm$form$children === void 0
            ? void 0
            : (_vm$form$children$_to2 = _vm$form$children._token) === null ||
              _vm$form$children$_to2 === void 0
            ? void 0
            : _vm$form$children$_to2.value) !== null &&
        _vm$form$children$_to !== void 0
          ? _vm$form$children$_to
          : null
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }