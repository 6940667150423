
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormChildType } from '@/components/form/serverForm';
import FServerFormChildPlaceholders from '@/components/form/f-server-form-child-placeholders.vue';
import PlaceholderOptions from '@/components/form/placeholders';
import ServerFormContentCache, { FServerFormContentCacheData } from '@/components/form/formCache';

@Component({
    components: { FServerFormChildPlaceholders },
})
export default class FServerFormLoader extends Vue {
    @Prop({ type: Boolean, default: false })
    hideLoader!: boolean;

    @Prop({ type: Boolean, default: false })
    loading!: boolean;

    @Prop({ type: Boolean, default: false })
    disablePlaceholders!: boolean;

    @Prop({ type: Object, required: true })
    formCache!: ServerFormContentCache;

    @Prop({ required: true })
    childPlaceholderMap!: Partial<Record<FormChildType, PlaceholderOptions>>|null;

    get placeholdersEnabled(): boolean {
        return this.cacheData.fields.length > 0 && !this.disablePlaceholders;
    }

    get cacheData(): FServerFormContentCacheData {
        return this.formCache.getData();
    }
}
