import axios, { AxiosError, AxiosResponse } from 'axios';

export function to<E = AxiosError, R = AxiosResponse>(promise): Promise<[E|undefined, R]> {
    return promise
        .then(function (data) { return [null, data]; })
        .catch(function (err) {
            return [err, undefined];
        });
}

export const httpClient = axios.create({
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
});
export const fastfoodClient = axios.create({
    withCredentials: true,
});
